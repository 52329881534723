<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="时间">
                <a-range-picker
                  @change="time"
                  format="YYYY-MM-DD HH:mm"
                  :show-time="{ format: 'HH:mm' }">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="类型">
                <a-select v-model="queryParam.order_type" placeholder="请选择" allow-clear>
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_type">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="来源">
                <a-select v-model="queryParam.source" placeholder="请选择" allow-clear>
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.order_source">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="创建人">
                <a-auto-complete
                  allow-clear
                  :data-source="createUsers"
                  key="id"
                  placeholder="请输入用户昵称"
                  @select="onSelect"
                  @search="onSearch"
                  @change="onChange"
                />
                <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-button type="primary" @click="loadData">查询</a-button>
            </a-col>
            <a-col :span="8">
              <a-statistic title="总销量" :value="this.mdl.total_count | numberFormat" style="margin-left: 50px">
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic title="总销售额" :value="(this.mdl.total_price /100).toFixed(2) | numberFormat">
              </a-statistic>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-table
        ref="table"
        size="small"
        :rowKey="(r, i)=>{ return r.goods_sn + r.brief + i }"
        :loading="loading"
        :columns="columns"
        :pagination="false"
        :data-source="orderStatisticsResource"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px; width: 140px;text-align: right">
          {{ (text/100).toFixed(2) }}
        </div>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { doRefund, orderToExamine, orderStatisticsList } from '@/api/applet_shopmall_order'
import { user_list } from '@/api/user'
export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      tag_value: '',
      orderStatisticsResource: [],
      createUsers: [],
      userlist: [],
      tip: '',
      refund_status_visible: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {
        total_count: 0,
        total_price: 0
      },
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '商品名称',
          ellipsis: true,
          dataIndex: 'brief'
        },
        {
          title: '商品编号',
          width: 180,
          dataIndex: 'goods_sn'
        },
        {
          title: '销量',
          width: 180,
          align: 'right',
          dataIndex: 's_count'
        },
        {
          title: '总额',
          dataIndex: 's_total_price',
          width: 260,
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        }
      ]
    }
  },
  methods: {
    handleDetail (record) {
      console.log(record.id)
      this.$router.push({ path: 'order/detail', query: { id: record.id } })
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.self_delivery_first = undefined
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    select_refund () {
      if (this.refund === 0) {
        this.$message.warning('请输入退款金额')
        return
      }
      const param = {
        refund: Math.round(this.refund * 100),
        reason: this.refund_reason,
        order_id: this.order_id
      }

      doRefund(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_examine (record) {
      this.select_status_visible = true
      // 2 审核通过，5 审核不通过
      this.order_id = record.id
    },
    // 订单退款
    handleRefund (record) {
      // this.refund_status_visible = true
      // // 2 审核通过，5 审核不通过
      // this.order_id = record.id
      this.$router.push({ path: 'order/detail_refund', query: { id: record.id } })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings[0] === '') {
        this.queryParam.start_time = undefined
        this.queryParam.end_time = undefined
        return
      }
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    // 加载数据方法 必须为 Promise 对象
    loadData () {
      if (this.queryParam.start_time === undefined || this.queryParam.end_time === undefined) {
        this.$message.warning('请选择开始时间和结束时间')
      } else {
        orderStatisticsList(Object.assign({}, this.queryParam))
          .then((res) => {
            console.log('订单管理：', res)
            this.mdl = res.data
            this.orderStatisticsResource = res.data.data
          })
      }
    },
    onChange (value) {
      console.log('onChange', value)
      if (value === undefined) {
        this.queryParam.create_by_id = undefined
      }
    },
    onSearch (searchText) {
      user_list({ nickname: searchText, role: 1 }).then((res) => {
        const reslut = res.data.entries || []
        this.userlist = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的用户'
        } else {
          this.tip = ''
        }
        this.createUsers = !searchText ? [] : reslut.map((item) => item.nickname)
      })
    },
    onSelect (value) {
      const filter = this.userlist.filter((item) => (item.nickname === value))
      console.log(value, '我是选择的值', filter)
      if (filter) {
        const user_id = filter[0].id + ''
        this.queryParam.create_by_id = user_id
      }
    }
  }
}
</script>
